import React, { useState } from 'react'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import clsx from 'clsx'
import { TextField } from '@mui/material'
import Icon from 'components/icon'
import GlobalUsage from './globalUsage'

const Calculator = () => {
  const [reason, setReason] = useState('')
  const [reasonText, setReasonText] = useState('')
  const [tab, setTab] = useState('step1')
  const { t } = useTranslation()

  // if (tab === 'step1') {
  //   return (
  //     <div className="container flex-col items-center pb-32 pt-24">
  //       <h2 className="text-blue-300 text-[28px] lg:text-[48px] leading-[1.2] text-center px-16 mb-16">
  //         <Trans i18nKey="costReduction.section2.title">
  //           <a className="text-secondary-600" />
  //         </Trans>
  //       </h2>
  //       <div className="flex flex-col gap-16 justify-center items-center lg:flex-row lg:-ml-10">
  //         <CommPanel
  //           textList={[
  //             t('costReduction.section2.swiper.text1'),
  //             t('costReduction.section2.swiper.text2'),
  //             t('costReduction.section2.swiper.text3'),
  //             t('costReduction.section2.swiper.text4'),
  //             t('costReduction.section2.swiper.text5')
  //           ]}
  //         />
  //         <figure className="cursor-pointer" onClick={() => setTab('step2')}>
  //           <I18nImage
  //             src={'/images/costReduction/sim_calculator.svg'}
  //             alt="sim_calculator"
  //             style={{ height: 'auto', maxWidth: '100%' }}
  //           />
  //         </figure>
  //       </div>
  //     </div>
  //   )
  // } else

  if (tab === 'step1') {
    return (
      // <Card className="w-[400px] md:w-[700px] md:h-[688px] px-4 py-10 md:px-20 md:py-20 flex flex-col justify-center items-center rounded-3xl shadow-xl">
      <div className="w-[400px] md:w-[700px] px-4 md:px-20 md:py-10 flex flex-col justify-center items-center">
        {/* <h2 className="mb-12 text-center font-bold text-[28px] md:text-[40px]">Your CDN is applied to</h2> */}
        <ul className="space-y-2 w-full">
          <li
            className={clsx(
              'text-[14px] md:text-[24px] py-5 px-3 md:px-7 border-solid  rounded flex justify-between items-center hover:border-blue-100 hover:text-blue-100 cursor-pointer group min-h-20 md:min-h-[88px]',
              reason === 'software-firmware'
                ? 'border-2 md:border-4 text-blue-100 border-blue-100'
                : 'border text-[#575757] border-[#CACACA]'
            )}
            onClick={() => setReason('software-firmware')}
          >
            <div className="flex items-center gap-3">
              <Icon fontSize="40px" className="hidden md:block" name="cloud_download" />
              <Icon fontSize="24px" className="md:hidden" name="cloud_download" />
              {t('costReduction.section2_5.calculator.contentType1')}
            </div>
            <div className="hidden md:block">
              {reason === 'software-firmware' ? <Icon fontSize="40px" name="done" /> : null}
            </div>
            <div className="md:hidden">
              {reason === 'software-firmware' ? <Icon fontSize="24px" name="done" /> : null}
            </div>
          </li>
          <li
            className={clsx(
              'text-[14px] md:text-[24px] py-5 px-3 md:px-7 border-solid rounded flex justify-between items-center hover:border-blue-100 hover:text-blue-100 cursor-pointer group min-h-20 md:min-h-[88px]',
              reason === 'dynamic-site-acceleration'
                ? 'border-2 md:border-4 text-blue-100 border-blue-100'
                : 'border text-[#575757] border-[#CACACA]'
            )}
            onClick={() => setReason('dynamic-site-acceleration')}
          >
            <div className="flex items-center gap-3">
              <Icon fontSize="40px" className="hidden md:block" name="speed" />
              <Icon fontSize="24px" className="md:hidden" name="speed" />
              {t('costReduction.section2_5.calculator.contentType2')}
            </div>
            <div className="hidden md:block">
              {reason === 'dynamic-site-acceleration' ? <Icon fontSize="40px" name="done" /> : null}
            </div>
            <div className="md:hidden">
              {reason === 'dynamic-site-acceleration' ? <Icon fontSize="24px" name="done" /> : null}
            </div>
          </li>
          <li
            className={clsx(
              'text-[14px] md:text-[24px] py-5 px-3 md:px-7 border-solid rounded flex justify-between items-center hover:border-blue-100 hover:text-blue-100 cursor-pointer group min-h-20 md:min-h-[88px]',
              reason === 'vod-short-video'
                ? 'border-2 md:border-4 text-blue-100 border-blue-100'
                : 'border text-[#575757] border-[#CACACA]'
            )}
            onClick={() => setReason('vod-short-video')}
          >
            <div className="flex items-center gap-3">
              <Icon fontSize="40px" className="hidden md:block" name="play_circle" />
              <Icon fontSize="24px" className="md:hidden" name="play_circle" />
              {t('costReduction.section2_5.calculator.contentType3')}
            </div>
            <div className="hidden md:block">
              {reason === 'vod-short-video' ? <Icon fontSize="40px" name="done" /> : null}
            </div>
            <div className="md:hidden">
              {reason === 'vod-short-video' ? <Icon fontSize="24px" name="done" /> : null}
            </div>
          </li>
          <li
            className={clsx(
              'text-[14px] md:text-[24px] py-5 px-3 md:px-7 border border-solid rounded flex justify-between items-center hover:border-blue-100 hover:text-blue-100 cursor-pointer group min-h-20 md:min-h-[88px]',
              reason === 'other'
                ? 'border-2 md:border-4 text-blue-100 border-blue-100'
                : 'border text-[#575757] border-[#CACACA]'
            )}
            onClick={() => setReason('other')}
          >
            <div className="flex items-center gap-3 flex-2">
              <Icon fontSize="40px" className="hidden md:block" name="dashboard_customize" />
              <Icon fontSize="24px" className="md:hidden" name="dashboard_customize" />
              <span>{t('costReduction.section2_5.calculator.contentType4')}</span>
              <TextField
                className="md:w-[250px]"
                variant="standard"
                value={reasonText}
                onChange={(e) => setReasonText(e.target.value)}
              />
            </div>
            <div className="hidden md:block">{reason === 'other' ? <Icon fontSize="40px" name="done" /> : null}</div>
            <div className="md:hidden">{reason === 'other' ? <Icon fontSize="24px" name="done" /> : null}</div>
          </li>
        </ul>
        <button
          className="text-[24px] md:text-[32px] text-[#FF5723] font-bold mt-12 disabled:opacity-20"
          disabled={!reason}
          onClick={() => setTab(reason)}
        >
          {t('costReduction.section2_5.calculator.cta')}
        </button>
      </div>
    )
  } else {
    return <GlobalUsage />
  }
}

export default Calculator
