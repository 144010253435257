import React, { useState } from 'react'
import I18nImage from 'components/gallery/i18nImage'
import CommPanel from 'components/panels/commPanel'
import { Link } from 'gatsby'

const OptimizedReason = () => {
  const [selectedOption, setSelectedOption] = useState(1)

  return (
    <div className="flex flex-col gap-16 justify-center items-center lg:flex-row lg:-ml-10">
      <CommPanel
        textList={[
          'costReduction.section2.swiper.text1',
          'costReduction.section2.swiper.text2',
          'costReduction.section2.swiper.text3',
          'costReduction.section2.swiper.text4',
          'costReduction.section2.swiper.text5'
        ]}
        idArray={[
          'btn_best_performance_cost_savings',
          'btn_best_cost_basic_performance',
          'btn_budget_efficiency_regions',
          'btn_balance_performance_cost',
          'btn_committed_usage_discounts'
        ]}
        selectedOption={selectedOption}
        setSelectedOption={setSelectedOption}
      />
      <div className="relative w-full sm:w-[500px] lg:max-w-[49%] h-[500px]">
        {Array.from({ length: 5 }, (_, index) => (
          <Link to="/lp/get-a-demo/" key={`demo_reason_${index}`}>
            <figure className="cursor-pointer">
              <I18nImage
                src={`/images/costReduction/optimize_your_costs_Q${index + 1}.svg`}
                alt="optimize_costs"
                style={{ height: 'auto', maxWidth: '100%' }}
                className={`absolute flex items-center left-0 top-2 lg:w-[600px] lg:max-h-[480px] transition-opacity duration-1000 ease-in-out ${
                  index + 1 === selectedOption ? 'opacity-100 scale-100' : 'opacity-0 scale-0 h-0'
                }`}
              />
            </figure>
          </Link>
        ))}
      </div>
    </div>
  )
}

export default OptimizedReason
