import React from 'react'
import { graphql, Link } from 'gatsby'
import { useTranslation, Trans } from 'gatsby-plugin-react-i18next'
import Layout from 'layouts/index'
import Seo from 'components/seo'
import I18nImage from 'components/gallery/i18nImage'
import Calculator from 'components/costReduction/calculator'
import OptimizedReason from 'components/costReduction/optimizedReason'

const NewCustomer = () => {
  const { t } = useTranslation()

  return (
    <>
      <figure className="flex-1">
        <I18nImage
          src={'/images/costReduction/saving_new_mlytics_customer.svg'}
          alt="saving_new_mlytics_customer"
          style={{ height: 'auto', maxWidth: '100%' }}
        />
      </figure>
      <article className="flex-1 flex flex-col gap-5">
        <h4 className="text-[28px]">{t('costReduction.section4.newCustomerBlock.subTitle')}</h4>
        <div className="space-y-8">
          <p className="text-gray-600">{t('costReduction.section4.newCustomerBlock.content1')}</p>
        </div>
        <div>
          <Link to="/lp/get-a-demo/" className="btn btn-md btn-bold btn-orange">
            {t('button.getDemo.1')}
          </Link>
        </div>
      </article>
    </>
  )
}

const ExistingCustomer = () => {
  const { t } = useTranslation()

  return (
    <>
      <figure className="flex-1">
        <I18nImage
          src={'/images/costReduction/saving_existing_mlytics_customer.svg'}
          alt="saving_existing_mlytics_customer"
          style={{ height: 'auto', maxWidth: '100%' }}
        />
      </figure>
      <article className="flex-1 flex flex-col gap-5">
        <h4 className="text-[28px]">{t('costReduction.section4.existingCustomerBlock.subTitle')}</h4>
        <div className="space-y-8">
          <p className="text-gray-600">{t('costReduction.section4.existingCustomerBlock.content1')}</p>
          <p className="text-gray-600">{t('costReduction.section4.existingCustomerBlock.content2')}</p>
        </div>
        <div>
          <Link to="/lp/get-a-demo/" className="btn btn-md btn-bold btn-orange">
            {t('button.getDemo.1')}
          </Link>
        </div>
      </article>
    </>
  )
}

const CostReductionPage = () => {
  const { t } = useTranslation()
  const [isNewCustomer, setIsNewCustomer] = React.useState(true)

  return (
    <Layout>
      <>
        <Seo
          seo={{
            metaTitle: t('costOfDelivery.meta.title'),
            metaDescription: t('costOfDelivery.meta.description'),
            metaUrl: 'https://www.mlytics.com/features/cost-of-delivery/',
            shareImage: {
              imageUrl: 'https://www.mlytics.com/wp-content/uploads/2023/03/og_v5.jpg',
              imageWidth: 1200,
              imageHeight: 630,
              imageType: 'image/jpeg'
            }
          }}
        />
        <section className="container flex flex-col-reverse items-center gap-16 lg:flex-row lg:gap-5 pb-32 pt-24">
          <div className="flex-1">
            <h2 className="text-primary-500 text-[28px] lg:text-start lg:text-[48px] leading-[1.2] text-center">
              <Trans i18nKey="costReduction.section1.title">
                <a className="text-secondary-600" />
              </Trans>
            </h2>
            <div className="text-gray-600 pt-5 pb-16 space-y-6 max-w-[550px]">
              <p>{t('costReduction.section1.sub.title')}</p>
              <p>{t('costReduction.section1.sub.item1')}</p>
              {/* <ul className="list-disc pl-6">
                <li>{t('costReduction.section1.sub.item1')}</li>
                <li>{t('costReduction.section1.sub.item2')}</li>
                <li>{t('costReduction.section1.sub.item3')}</li>
              </ul> */}
            </div>
            <Link to="/lp/get-a-demo/" className="btn btn-md btn-bold btn-orange">
              {t('button.getDemo.1')}
            </Link>
          </div>
          <div className="flex flex-1 items-center justify-center">
            <I18nImage
              src={'/images/costReduction/cost_reduction_hero_new.svg'}
              alt="cost_reduction_hero"
              style={{ height: 'auto', maxWidth: '100%' }}
            />
          </div>
        </section>
        <section className="bg-blue-300/10 relative w-full overflow-hidden h-full items-center justify-center">
          {/* 背景網格 */}
          <div className="grid grid-cols-6 grid-rows-1 md:grid-cols-9 absolute z-[-5] top-0 w-full h-full items-stretch max-w-[1366px] mx-auto">
            {Array.from({ length: 9 }, (_, index) => (
              <div
                key={`${index}_grids`}
                className={`border border-transparent items-stretch ${
                  index === 0 ? 'border-x-blue-50/20' : 'border-r-blue-50/20'
                }`}
              />
            ))}
          </div>
          <div className="absolute left-0 top-0 mt-[6px] sm:mt-[10px] w-full h-full z-[-3]  min-h-[1000px]">
            {Array.from({ length: 19 }, (_, index) => (
              <div key={index} className=" border border-transparent border-t-blue-50/20 w-full h-40"></div>
            ))}
          </div>

          {/* Content */}
          <div className="container flex justify-center pb-8 sm:pb-32 pt-24">
            <div className="flex-col items-center">
              <h2 className="text-blue-300 text-[28px] lg:text-[48px] leading-[1.2] text-center px-16 mb-16">
                <Trans i18nKey="costReduction.section2.title">
                  <a className="text-secondary-600" />
                </Trans>
              </h2>
              <OptimizedReason />
            </div>
          </div>
        </section>
        <section className="container pt-24">
          <div className="text-center mb-16">
            <h2 className="text-blue-300 text-[28px] lg:text-[48px] leading-[1.2] text-center px-16">
              <Trans i18nKey="costReduction.section2_5.title">
                <a className="text-secondary-600" />
              </Trans>
            </h2>
          </div>

          <div className="flex justify-center">
            <Calculator />
          </div>
        </section>
        <section className="container py-24">
          <div className="text-center mb-16">
            <h2 className="text-blue-300 text-[28px] lg:text-[48px] leading-[1.2] text-center px-16">
              {t('costReduction.section3.title')}
            </h2>
            <p className="text-blue-300 text-[20px] lg:text-[36px] leading-[1.2] font-normal text-center px-16">
              {t('costReduction.section3.subtitle')}
            </p>
          </div>
          <div className="flex flex-col justify-center items-center gap-16 lg:gap-20 lg:flex-row">
            <figure className="flex-1 flex justify-center items-center">
              <I18nImage
                src={'/images/costReduction/saving_before_after.svg'}
                alt="saving_before_after"
                style={{ height: 'auto', maxWidth: '100%' }}
              />
            </figure>
            <div className="flex-1 flex flex-col gap-[28px]">
              <article className="flex gap-3">
                <i>
                  <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <mask
                      id="mask0_266_330"
                      style={{ maskType: 'alpha' }}
                      maskUnits="userSpaceOnUse"
                      x="0"
                      y="0"
                      width="60"
                      height="60"
                    >
                      <circle cx="30" cy="30" r="30" fill="#E82C2A" />
                    </mask>
                    <g mask="url(#mask0_266_330)">
                      <circle opacity="0.2" cx="30" cy="30" r="30" fill="#E82C2A" />
                      <path d="M0 30H61" stroke="#E82C2A" strokeWidth="6" />
                    </g>
                  </svg>
                </i>
                <div>
                  <div className="text-[24px] text-error-500 mb-3">
                    <h4>{t('costReduction.section3.article1.title1')}</h4>
                    <h4>{t('costReduction.section3.article1.title2')}</h4>
                  </div>
                  <p className="text-gray-600">{t('costReduction.section3.article1.description')}</p>
                </div>
              </article>
              <article className="flex gap-3">
                <i>
                  <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <mask
                      id="mask0_266_338"
                      style={{ maskType: 'alpha' }}
                      maskUnits="userSpaceOnUse"
                      x="0"
                      y="0"
                      width="60"
                      height="60"
                    >
                      <circle cx="30" cy="30" r="30" fill="#21838D" />
                    </mask>
                    <g mask="url(#mask0_266_338)">
                      <circle opacity="0.2" cx="30" cy="30" r="30" fill="#21838D" />
                      <path
                        d="M18 12H21V9H18V12ZM15 27V30H21V27H15ZM3 15H18V9H3V15ZM15 12V27H21V12H15Z"
                        fill="#21838D"
                      />
                      <path
                        d="M33 27H36V24H33V27ZM30 42V45H36V42H30ZM18 30H33V24H18V30ZM30 27V42H36V27H30Z"
                        fill="#21838D"
                      />
                      <path
                        d="M48 42H51V39H48V42ZM45 57V60H51V57H45ZM33 45H48V39H33V45ZM45 42V57H51V42H45Z"
                        fill="#21838D"
                      />
                    </g>
                  </svg>
                </i>
                <div>
                  <div className="text-[24px] text-blue-200 mb-3">
                    <h4>{t('costReduction.section3.article2.title1')}</h4>
                    <h4>{t('costReduction.section3.article2.title2')}</h4>
                  </div>
                  <p className="text-gray-600">{t('costReduction.section3.article2.description')}</p>
                </div>
              </article>
            </div>
          </div>
        </section>
        <section className="container py-14 flex flex-col items-center">
          <h2 className="text-[48px] text-blue-300 mb-5">{t('costReduction.section4.title')}</h2>
          <div className="flex items-center gap-5 mb-16">
            <h3 className="text-[28px lg:text-[40px] font-light">
              <Trans
                i18nKey={
                  isNewCustomer
                    ? 'costReduction.section4.newCustomerBlock.title'
                    : 'costReduction.section4.existingCustomerBlock.title'
                }
              >
                <b className="text-blue-100" />
              </Trans>
            </h3>
            <button onClick={() => setIsNewCustomer((prev) => !prev)} className="flex-shrink-0 select-none">
              <I18nImage src={'/images/costReduction/article_toggle.svg'} alt="toggle" />
            </button>
          </div>
          <div className="flex flex-col gap-12 min-h-[640px] min-h lg:min-h-80 lg:flex-row lg:gap-20">
            {isNewCustomer ? <NewCustomer /> : <ExistingCustomer />}
          </div>
        </section>
        <section className="bg-primary-500">
          <div className="container py-14 flex flex-col gap-7 justify-center items-center">
            <p className="text-[28px] lg:text-[40px] text-white leading-[1.2] text-center">
              <Trans i18nKey="costReduction.section5.title">
                <b className="font-bold" />
              </Trans>
            </p>
            <Link to="/lp/get-a-demo/" className="btn btn-md btn-bold btn-orange">
              {t('button.getDemo.1')}
            </Link>
          </div>
        </section>
      </>
    </Layout>
  )
}

export default CostReductionPage

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
