import React from 'react'
import { setImagePostfix } from 'utils/i18n'

const i18nImage = ({ src = '', ...props }) => {
  return (
    <img
      {...props}
      src={setImagePostfix(src)}
      onError={(event) => {
        event.target.onerror = null
        event.target.src = src
      }}
    />
  )
}

export default i18nImage
