import React, { useMemo, useState } from 'react'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import { Link } from 'gatsby'
import { Card, TextField, Slider, Select, MenuItem } from '@mui/material'
import { styled } from '@mui/material/styles'
import clsx from 'clsx'
import Icon from 'components/icon'
import { formatNumberWithCommas } from 'utils'

const UsageSlider = styled(Slider)({
  color: '#10A1AF',
  height: 10,
  borderRadius: '4 0 0 4',
  '& .MuiSlider-track': {
    border: 'none'
  },
  '& .MuiSlider-thumb': {
    height: 28,
    width: 16,
    backgroundColor: '#21838D',
    borderRadius: 4,
    '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
      boxShadow: 'inherit'
    },
    '&::before': {
      display: 'none'
    }
  },
  '& .MuiSlider-rail': {
    color: '#9CB1B4'
  }
})

const StyledTextField = styled(TextField)`
  margin-bottom: -12px;
  .MuiInput-underline:before {
    bottom: 12px;
  }
  && .MuiInput-underline:hover:before {
    bottom: 12px;
  }
  .MuiInput-underline:after {
    bottom: 12px;
  }
`

const StyledSelect = styled(Select)`
  margin-bottom: -12px;
  &:before {
    bottom: 12px;
  }
  &:hover:before {
    bottom: 12px;
  }
  &:after {
    bottom: 12px;
  }
`

const GIGABYTE = 1024
const tieredPricingList = [
  { limit: 10 * GIGABYTE, price: 0.12, oneYearDiscountedPrice: 0.0324, threeYearDiscountedPrice: 0.00972 },
  { limit: 50 * GIGABYTE, price: 0.1, oneYearDiscountedPrice: 0.027, threeYearDiscountedPrice: 0.0081 },
  { limit: 150 * GIGABYTE, price: 0.095, oneYearDiscountedPrice: 0.02565, threeYearDiscountedPrice: 0.007695 },
  { limit: 500 * GIGABYTE, price: 0.09, oneYearDiscountedPrice: 0.0324, threeYearDiscountedPrice: 0.00972 },
  { limit: 1 * GIGABYTE * GIGABYTE, price: 0.08, oneYearDiscountedPrice: 0.027, threeYearDiscountedPrice: 0.0081 },
  { limit: 5 * GIGABYTE * GIGABYTE, price: 0.07, oneYearDiscountedPrice: 0.02565, threeYearDiscountedPrice: 0.007695 },
  { limit: 15 * GIGABYTE * GIGABYTE, price: 0.06, oneYearDiscountedPrice: 0.0243, threeYearDiscountedPrice: 0.00729 },
  { limit: 30 * GIGABYTE * GIGABYTE, price: 0.06, oneYearDiscountedPrice: 0.007, threeYearDiscountedPrice: 0.0049 },
  { limit: Infinity, price: 0.06, oneYearDiscountedPrice: 0.005, threeYearDiscountedPrice: 0.0035 }
]

const calculatePricing = (usageGB) => {
  let remaining = usageGB
  let totalPrice = 0
  let totalOneYearDiscountedPrice = 0
  let totalThreeYearDiscountedPrice = 0
  let previousLimit = 0

  console.log(`Tiered price for: ${formatNumberWithCommas(usageGB)} GB`)

  for (let tier of tieredPricingList) {
    if (remaining <= 0) break
    const currentUsage = Math.min(remaining, tier.limit - previousLimit)

    totalPrice += currentUsage * tier.price
    totalOneYearDiscountedPrice += currentUsage * tier.oneYearDiscountedPrice
    totalThreeYearDiscountedPrice += currentUsage * tier.threeYearDiscountedPrice
    remaining -= currentUsage
    previousLimit = tier.limit

    console.log(
      `${formatNumberWithCommas(currentUsage)} GB x ${tier.oneYearDiscountedPrice} USD = ${formatNumberWithCommas(
        currentUsage * tier.oneYearDiscountedPrice
      )} USD`
    )
  }

  console.log(`Total price: ${formatNumberWithCommas(totalPrice)} USD`)

  return {
    originalPrice: totalPrice,
    oneYearDiscountedPrice: totalOneYearDiscountedPrice,
    threeYearDiscountedPrice: totalThreeYearDiscountedPrice
  }
}

const GlobalUsage = () => {
  const [usageValue, setUsageValue] = useState(200)
  const [unit, setUnit] = useState('TB')
  const [contractYear, setContractYear] = useState(0)
  const { t } = useTranslation()

  const displayPrice = useMemo(() => {
    const usageGBValue = unit === 'PB' ? usageValue * GIGABYTE * GIGABYTE : usageValue * GIGABYTE

    const { originalPrice, oneYearDiscountedPrice, threeYearDiscountedPrice } = calculatePricing(usageGBValue)
    const discountedPrice =
      contractYear === 0 ? originalPrice : contractYear === 1 ? oneYearDiscountedPrice : threeYearDiscountedPrice

    const discountOff = originalPrice === 0 ? 0 : ((originalPrice - discountedPrice) / originalPrice) * 100

    console.log('originalPrice', originalPrice)
    console.log('discountedPrice', discountedPrice)

    return {
      originalPrice:
        String(originalPrice).split('.')[0].length > 5 ? originalPrice.toFixed(0) : originalPrice.toFixed(1),
      discountedPrice:
        String(discountedPrice).split('.')[0].length > 5 ? discountedPrice.toFixed(0) : discountedPrice.toFixed(1),
      discountOff: discountOff.toFixed(1)
    }
  }, [usageValue, unit, contractYear])

  const handleUsageValueControl = (e) => {
    e.preventDefault()
    switch (e.key) {
    case 'ArrowUp':
      setUsageValue((prev) => (prev < 1000 ? prev + 1 : prev))
      break
    case 'ArrowDown':
      setUsageValue((prev) => (prev > 1 ? prev - 1 : prev))
      break
    case 'ArrowRight':
      setUsageValue((prev) => {
        if (prev + 10 <= 1000) return prev + 10

        return 1000
      })
      break
    case 'ArrowLeft':
      setUsageValue((prev) => {
        if (prev - 10 >= 1) return prev - 10
        return 1
      })
      break
    case '1':
    case '2':
    case '3':
    case '4':
    case '5':
    case '6':
    case '7':
    case '8':
    case '9':
    case '0':
      setUsageValue((prev) => {
        if (Number(prev.toString() + e.key) > 1000) {
          return 1000
        }

        return Number(prev.toString() + e.key)
      })
      break
    case 'Backspace':
      setUsageValue((prev) => {
        if (Math.floor(prev / 10) < 1) {
          return 1
        }
        return Math.floor(prev / 10)
      })
      break
    }
  }

  return (
    <Card
      className="w-[400px] md:w-[700px] p-0 flex flex-col justify-center items-center shadow-xl select-none"
      sx={{ borderRadius: '24px', boxShadow: '0px 4px 16px 0px rgba(0, 0, 0, 0.15)' }}
    >
      <div className="pt-8 px-3 md:px-9 w-[360px] md:w-[650px]">
        <h2 className="mb-8 text-center font-bold text-[36px] md:text-[40px]">
          {t('costReduction.section2_5.calculator.title')}
        </h2>
        <div className="flex flex-col-reverse md:flex-col">
          <UsageSlider
            valueLabelDisplay="off"
            aria-label="usage slider"
            min={1}
            max={1000}
            value={usageValue}
            onChange={(e) => setUsageValue(e.target.value)}
          />
          <div className="hidden md:flex flex-col gap-6 w-full">
            <div className="flex items-center justify-space w-full">
              <div className="flex-1 flex space-x-1 w-[265px]">
                <StyledTextField
                  className="md:max-w-[160px]"
                  inputProps={{
                    style: {
                      color: '#575757',
                      fontSize: '60px',
                      lineHeight: '60px',
                      textAlign: 'right',
                      paddingRight: '16px',
                      paddingBottom: '-16px',
                      fontWeight: 700
                    }
                  }}
                  variant="standard"
                  value={usageValue}
                  onChange={(e) => {
                    if (e.target.value >= 1 && e.target.value <= 1000) setUsageValue(e.target.value)
                  }}
                  onKeyDown={handleUsageValueControl}
                />
                <StyledSelect
                  sx={{
                    color: '#575757',
                    fontSize: '40px',
                    fontWeight: 700,
                    paddingTop: '20px'
                  }}
                  variant="standard"
                  value={unit}
                  onChange={(e) => setUnit(e.target.value)}
                >
                  <MenuItem sx={{ fontSize: '24px' }} value={'TB'}>
                    TB
                  </MenuItem>
                  <MenuItem sx={{ fontSize: '24px' }} value={'PB'}>
                    PB
                  </MenuItem>
                </StyledSelect>
              </div>
              <div className="flex-1 self-end flex justify-between items-center gap-1">
                <Icon
                  fontSize={unit === 'TB' ? '48px' : '36px'}
                  className={clsx('self-end', {
                    'text-[#CACACA66]': contractYear !== 0
                  })}
                  name="play_arrow"
                  iconStyle="round"
                />
                <div className="flex-1 self-end flex justify-end items-end text-[#575757] w-[265px]">
                  <span className="text-[28px] font-normal mr-2">USD</span>
                  <span
                    className={clsx('font-bold', {
                      'text-[30px]': unit === 'TB',
                      'text-[24px] mb-1': unit === 'PB',
                      'line-through': contractYear !== 0 && usageValue > 0
                    })}
                  >
                    {contractYear === 0
                      ? formatNumberWithCommas(displayPrice.originalPrice)
                      : String(displayPrice.originalPrice / (12 * contractYear)).split('.')[0].length > 5
                        ? formatNumberWithCommas((displayPrice.originalPrice / (12 * contractYear)).toFixed(0))
                        : formatNumberWithCommas((displayPrice.originalPrice / (12 * contractYear)).toFixed(1))}
                  </span>
                  <span className="text-[24px] font-light">/{t('costReduction.section2_5.calculator.month')}</span>
                </div>
              </div>
            </div>
            <div className={clsx('flex items-center justify-space w-full', { 'opacity-0': contractYear === 0 })}>
              <div className="flex-1 w-[265px]" />
              <div className="flex-1 self-end flex justify-between items-center gap-1">
                <Icon fontSize={unit === 'TB' ? '48px' : '36px'} name="play_arrow" iconStyle="round" />
                <div className="flex-1 self-end flex justify-end items-end text-[#575757] w-[265px]">
                  <span className="text-[28px] font-normal mr-2">USD</span>
                  <span
                    className={clsx('font-bold', {
                      'text-[30px]': unit === 'TB',
                      'text-[24px] mb-1': unit === 'PB'
                    })}
                  >
                    {contractYear === 0
                      ? formatNumberWithCommas(displayPrice.discountedPrice)
                      : String(displayPrice.discountedPrice / (12 * contractYear)).split('.')[0].length > 5
                        ? formatNumberWithCommas((displayPrice.discountedPrice / (12 * contractYear)).toFixed(0))
                        : formatNumberWithCommas((displayPrice.discountedPrice / (12 * contractYear)).toFixed(1))}
                  </span>
                  <span className="text-[24px] font-light">/{t('costReduction.section2_5.calculator.month')}</span>
                </div>
              </div>
            </div>
          </div>
          <div className="flex md:hidden flex-col gap-6 w-full">
            <div className="flex items-center justify-space w-full">
              <div className="flex space-x-1 w-[125px]">
                <StyledTextField
                  className="max-w-[65px]"
                  inputProps={{
                    style: {
                      color: '#575757',
                      fontSize: '20px',
                      lineHeight: '20px',
                      textAlign: 'right',
                      paddingRight: '8px',
                      paddingBottom: '-8px',
                      fontWeight: 700
                    }
                  }}
                  variant="standard"
                  value={usageValue}
                  onChange={(e) => {
                    if (e.target.value >= 1 && e.target.value <= 1000) setUsageValue(e.target.value)
                  }}
                  onKeyDown={handleUsageValueControl}
                />
                <StyledSelect
                  sx={{
                    color: '#575757',
                    fontSize: '18px',
                    fontWeight: 700
                  }}
                  variant="standard"
                  value={unit}
                  onChange={(e) => setUnit(e.target.value)}
                >
                  <MenuItem value={'TB'}>TB</MenuItem>
                  <MenuItem value={'PB'}>PB</MenuItem>
                </StyledSelect>
              </div>
              <div className="flex-1 self-end flex justify-between items-center gap-1">
                <Icon
                  fontSize={unit === 'TB' ? '28px' : '24px'}
                  className={clsx({
                    'text-[#CACACA66]': contractYear !== 0
                  })}
                  name="play_arrow"
                  iconStyle="round"
                />
                <div className="self-end flex justify-end items-end text-[#575757] w-[160px]">
                  <span className="font-normal mr-2">USD</span>
                  <span
                    className={clsx('font-bold', {
                      'text-[18px]': unit === 'TB',
                      'text-[16px] mb-1': unit === 'PB',
                      'line-through': contractYear !== 0 && usageValue > 0
                    })}
                  >
                    {contractYear === 0
                      ? formatNumberWithCommas(displayPrice.originalPrice)
                      : String(displayPrice.originalPrice / (12 * contractYear)).split('.')[0].length > 5
                        ? formatNumberWithCommas((displayPrice.originalPrice / (12 * contractYear)).toFixed(0))
                        : formatNumberWithCommas((displayPrice.originalPrice / (12 * contractYear)).toFixed(1))}
                  </span>
                  <span className="font-light">/{t('costReduction.section2_5.calculator.month')}</span>
                </div>
              </div>
            </div>
            <div className={clsx('flex items-center justify-space w-full', { 'opacity-0': contractYear === 0 })}>
              <div className="w-[125px]" />
              <div className="flex-1 self-end flex justify-between items-center gap-1">
                <Icon fontSize={unit === 'TB' ? '28px' : '24px'} name="play_arrow" iconStyle="round" />
                <div className="self-end flex justify-end items-end text-[#575757] w-[160px]">
                  <span className="font-normal mr-2">USD</span>
                  <span
                    className={clsx('font-bold', {
                      'text-[18px]': unit === 'TB',
                      'text-[16px] mb-1': unit === 'PB'
                    })}
                  >
                    {contractYear === 0
                      ? formatNumberWithCommas(displayPrice.discountedPrice)
                      : String(displayPrice.discountedPrice / (12 * contractYear)).split('.')[0].length > 5
                        ? formatNumberWithCommas((displayPrice.discountedPrice / (12 * contractYear)).toFixed(0))
                        : formatNumberWithCommas((displayPrice.discountedPrice / (12 * contractYear)).toFixed(1))}
                  </span>
                  <span className="font-light">/{t('costReduction.section2_5.calculator.month')}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="bg-[#9CB1B433] w-full rounded-[18px] px-4 md:px-5 pt-3 md:pt-7 pb-3 mt-8 flex flex-col gap-5">
          <div>
            <span className="md:text-[28px] font-bold">{t('costReduction.section2_5.calculator.commitUsage')}</span>
            <span className="hidden md:inline-block px-4">
              <Select
                className="pl-4 pr-2"
                sx={{
                  color: '#575757',
                  fontSize: '32px',
                  fontWeight: 700,
                  paddingTop: '8px'
                }}
                variant="standard"
                value={contractYear}
                onChange={(e) => setContractYear(e.target.value)}
              >
                <MenuItem sx={{ fontSize: '18px' }} value={0}>
                  0
                </MenuItem>
                <MenuItem sx={{ fontSize: '18px' }} value={1}>
                  1
                </MenuItem>
                <MenuItem sx={{ fontSize: '18px' }} value={3}>
                  3
                </MenuItem>
              </Select>
            </span>
            <span className="md:hidden px-1">
              <Select
                sx={{
                  color: '#575757',
                  fontSize: '20px',
                  fontWeight: 700,
                  paddingTop: '8px'
                }}
                variant="standard"
                value={contractYear}
                onChange={(e) => setContractYear(e.target.value)}
              >
                <MenuItem value={0}>0</MenuItem>
                <MenuItem value={1}>1</MenuItem>
                <MenuItem value={3}>3</MenuItem>
              </Select>
            </span>
            <span className="md:text-[24px] font-light">{t('costReduction.section2_5.calculator.year')}</span>
            <span className="md:text-[24px] text-blue-100">
              {t('costReduction.section2_5.calculator.get')}
              <span className="text-[18px] md:text-[32px] font-bold pl-1">{displayPrice.discountOff}</span>
              {t('costReduction.section2_5.calculator.percentDiscount')}
            </span>
          </div>
          <div className="bg-white py-3 px-5 rounded-[20px] md:text-[20px] text-[#575757] flex items-center flex-wrap gap-2">
            <span>{t('costReduction.section2_5.calculator.total')}</span>
            <span className={clsx({ 'line-through': contractYear !== 0 && usageValue > 0 })}>
              USD {formatNumberWithCommas(displayPrice.originalPrice)}
            </span>
            {contractYear !== 0 && (
              <>
                <Icon fontSize="18px" name="arrow_forward" />
                <span>USD {formatNumberWithCommas(displayPrice.discountedPrice)},</span>
                <span className="text-blue-100 md:text-[20px] font-bold">
                  {t('costReduction.section2_5.calculator.save')} USD $
                  {formatNumberWithCommas((displayPrice.originalPrice - displayPrice.discountedPrice).toFixed(2))}
                </span>
              </>
            )}
          </div>
        </div>
      </div>
      <Link
        to="/lp/get-a-demo/"
        className="bg-[#FF5723] text-white text-[28px] md:text-[32px] font-bold w-full h-[80px] md:h-[100px] mt-[34px] flex gap-2.5 justify-center items-center"
      >
        <span>{t('costReduction.section2_5.calculator.scheduleDemo')}</span>
        <Icon className="md:hidden" fontSize="32px" name="arrow_forward" />
        <Icon className="hidden md:block" fontSize="40px" name="arrow_forward" />
      </Link>
    </Card>
  )
}

export default GlobalUsage
