import React from 'react'
import { Trans } from 'gatsby-plugin-react-i18next'
import { CSSTransition, TransitionGroup } from 'react-transition-group'
import PropTypes from 'prop-types'
import * as styles from 'styles/videoStreamAnalyticsSolution.module.css'

const CommPanel = ({ textList, idArray = [], selectedOption, setSelectedOption }) => {
  // 簡化函式名稱
  const handleOptionChange = (optionIndex) => setSelectedOption(optionIndex)
  return (
    <div className="relative flex justify-center items-center max-w-[455px] bg-grey min-h-50 p-6 rounded-3xl transition-all duration-300 ease-in-out">
      {/* panel background */}
      <div className="absolute w-full h-full bg-white top-0 left-0 z-[-3] rounded-3xl "></div>
      <div className="absolute w-full h-full bg-blue-300/5 top-0 left-0 z-[-2] rounded-3xl"></div>
      <div
        className="absolute w-full h-full bg-blue-50/20 top-0 left-0 z-[-1] rounded-3xl opacity-30"
        style={{ boxShadow: '0px 0px 20px 0px #2C5E63 inset, 0px 0px 8px 0px #000 inset' }}
      ></div>
      {/* panel background */}
      <div className={`relative w-full h-auto flex justify-center flex-col overflow-hidden transition-all ease-in-out`}>
        <TransitionGroup className="h-full">
          {textList.map((text, index) => (
            <CSSTransition
              classNames={{
                enter: styles.colEnter,
                enterActive: styles.colEnterActive,
                exit: styles.colExit,
                exitActive: styles.colExitActive,
                exitDone: styles.colExitDone
              }}
              appear
              key={`listGroup_${index}`}
              timeout={200}
              unmountOnExit
            >
              <div
                key={`${index}_selectPanel`}
                onClick={() => handleOptionChange(index + 1)}
                className={`${
                  index + 1 === selectedOption ? 'bg-white border-blue-200 border-l-[10px]' : 'ml-2 border-transparent'
                } cursor-pointer min-h-[84px] flex items-center border-2 transition-all ease-in-out duration-200 rounded-r hover:bg-white py-4 px-5 select-none`}
                clickid={idArray[index]}
              >
                {/* {text} */}
                <p className="text-xl">
                  <Trans i18nKey={text}>
                    <b className="font-bold" />
                  </Trans>
                </p>
              </div>
            </CSSTransition>
          ))}
        </TransitionGroup>
      </div>

      <div className="absolute rounded-full transition-all duration-300 ease-in-out border-2 top-auto left-[-.8rem] items-center bg-white border-black flex flex-col w-[30px] py-3 px-0 min-h-[50px]">
        {/* dots */}
        {textList.map((_, index) => (
          <div
            key={`${index}_dot`}
            onClick={() => handleOptionChange(index)}
            className={`transition-all ease-in-out duration-200	 flex my-1 mx-0 rounded-full ${
              selectedOption === index + 1 ? 'bg-blue-200  w-2.5 h-2.5' : 'bg-gray-300  w-1.5 h-1.5'
            }  `}
          />
        ))}
        {/* dots */}
      </div>
    </div>
  )
}

CommPanel.propTypes = {
  textList: PropTypes.array.isRequired,
  idArray: PropTypes.array,
  selectedOption: PropTypes.number.isRequired,
  setSelectedOption: PropTypes.func.isRequired
}

export default CommPanel
